"use strict";
"use es6";

// These hooks are used internally and are not exported publicly by the library
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "__use", {
  enumerable: true,
  get: function () {
    return _use.__use;
  }
});
Object.defineProperty(exports, "useDeepMemo", {
  enumerable: true,
  get: function () {
    return _useDeepMemo.useDeepMemo;
  }
});
Object.defineProperty(exports, "useIsomorphicLayoutEffect", {
  enumerable: true,
  get: function () {
    return _useIsomorphicLayoutEffect.useIsomorphicLayoutEffect;
  }
});
Object.defineProperty(exports, "useLazyRef", {
  enumerable: true,
  get: function () {
    return _useLazyRef.useLazyRef;
  }
});
Object.defineProperty(exports, "useRenderGuard", {
  enumerable: true,
  get: function () {
    return _useRenderGuard.useRenderGuard;
  }
});
Object.defineProperty(exports, "wrapHook", {
  enumerable: true,
  get: function () {
    return _wrapHook.wrapHook;
  }
});
var _useDeepMemo = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/useDeepMemo");
var _useIsomorphicLayoutEffect = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/useIsomorphicLayoutEffect");
var _useRenderGuard = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/useRenderGuard");
var _useLazyRef = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/useLazyRef");
var _use = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/__use");
var _wrapHook = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/wrapHook");