"use strict";
"use es6";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFragment = useFragment;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var React = _interopRequireWildcard(require("react"));
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
var _useSyncExternalStore = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSyncExternalStore");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
var _index3 = _interopRequireDefault(require("apollo-stack-hubspot/internal/@wry/equality/lib/index"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useFragment(options) {
  return (0, _index2.wrapHook)("useFragment", _useFragment, (0, _useApolloClient.useApolloClient)(options.client))(options);
}
function _useFragment(options) {
  var cache = (0, _useApolloClient.useApolloClient)(options.client).cache;
  var diffOptions = (0, _index2.useDeepMemo)(function () {
    var fragment = options.fragment,
      fragmentName = options.fragmentName,
      from = options.from,
      _a = options.optimistic,
      optimistic = _a === void 0 ? true : _a,
      rest = (0, _tslib.__rest)(options, ["fragment", "fragmentName", "from", "optimistic"]);
    return (0, _tslib.__assign)((0, _tslib.__assign)({}, rest), {
      returnPartialData: true,
      id: typeof from === "string" ? from : cache.identify(from),
      query: cache["getFragmentDoc"](fragment, fragmentName),
      optimistic: optimistic
    });
  }, [options]);
  var resultRef = (0, _index2.useLazyRef)(function () {
    return diffToResult(cache.diff(diffOptions));
  });
  var stableOptions = (0, _index2.useDeepMemo)(function () {
    return options;
  }, [options]);
  // Since .next is async, we need to make sure that we
  // get the correct diff on the next render given new diffOptions
  React.useMemo(function () {
    resultRef.current = diffToResult(cache.diff(diffOptions));
  }, [diffOptions, cache]);
  // Used for both getSnapshot and getServerSnapshot
  var getSnapshot = React.useCallback(function () {
    return resultRef.current;
  }, []);
  return (0, _useSyncExternalStore.useSyncExternalStore)(React.useCallback(function (forceUpdate) {
    var lastTimeout = 0;
    var subscription = cache.watchFragment(stableOptions).subscribe({
      next: function (result) {
        if ((0, _index3.default)(result, resultRef.current)) return;
        resultRef.current = result;
        // If we get another update before we've re-rendered, bail out of
        // the update and try again. This ensures that the relative timing
        // between useQuery and useFragment stays roughly the same as
        // fixed in https://github.com/apollographql/apollo-client/pull/11083
        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(forceUpdate);
      }
    });
    return function () {
      subscription.unsubscribe();
      clearTimeout(lastTimeout);
    };
  }, [cache, stableOptions]), getSnapshot, getSnapshot);
}
function diffToResult(diff) {
  var result = {
    data: diff.result,
    complete: !!diff.complete
  };
  if (diff.missing) {
    result.missing = (0, _index.mergeDeepArray)(diff.missing.map(function (error) {
      return error.missing;
    }));
  }
  return result;
}